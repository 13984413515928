import './App.css';

import Checkbox from "@mui/material/Checkbox"
import { Card, CardContent, FormControlLabel, FormGroup, Typography, Box, Link, AppBar, Toolbar, Grid, Select, Autocomplete, TextField, TableContainer, TableHead, TableRow, TableCell, TableBody, Table, Paper, Skeleton, Snackbar, SnackbarOrigin, Alert } from '@mui/material';
import { Container } from '@mui/system';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { all } from "iso-3166-1"
import { Country } from 'iso-3166-1/dist/iso-3166';
import { DateTime } from "luxon"

import genreData from "./genres.json"

interface IGenre {
  name: string,
  id: number
};

function GenreCheckbox(config: IGenre & { onChange: (newValue: boolean, id: number) => void }) {

  const [checked, setChecked] = useState(false);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setChecked(evt.target.checked);

    console.log("calling on change", evt.target.checked, config.id);

    config.onChange(evt.target.checked, config.id);
  }

  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label={config.name}></FormControlLabel>
    </FormGroup>
  );
}

interface State extends SnackbarOrigin {
  open: boolean;
  errorMessage: string;
}

type Checked = (IGenre & { checked: boolean });

function applyCheckboxesToUrl(url, checkboxes : Array<Checked>) {

  const checkedBoxes = checkboxes.filter(i => i.checked);

  if (checkedBoxes.length > 0) {
    url.searchParams.set("genres", checkedBoxes.map(i => i.name).join(","));
  }
  else {
    url.searchParams.delete("genres");
  }
  return url;
}

function App() {

  const baseUrl = useMemo(() => {
    const url = new URL(window.location.origin);

    url.host = "api." + url.host;

    return url;
  }, []);

  
  const [state, setState] = useState<State>({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    errorMessage: null
  });

  const [genres, setGenres] = useState<IGenre[]>([]);

  // Fetch genres on load
  useEffect(() => {
    setGenres(genreData);
  }, [baseUrl])

  // Setup buffer when genres load
  useEffect(() => {
    setCheckboxes(genres.map(genre => ({ ...genre, checked: false })));
  }, [genres])

  const countries = all();

  const [region, setRegion] = useState<Country>(countries.find(i => i.alpha2 === "GB"));
  const [checkboxes, setCheckboxes] = useState<Array<Checked>>([]);

  const [filteredUrl, setFilteredUrl] = useState<string>("");
  const [calendarUrl, setCalendarUrl] = useState<string>("");

  const apiUrl = useMemo(() => {
    return new URL(`/api/${region.alpha2}`, baseUrl);
  }, [region, baseUrl]);

  
  // Calculate the URL
  useEffect(() => {

    let url = new URL(apiUrl);

    url = applyCheckboxesToUrl(url, checkboxes);

    setFilteredUrl(url.toString());
  }, [region, checkboxes, baseUrl, apiUrl]);

  useEffect(() => {

    if (filteredUrl !== "") {

      console.log("Filtered", filteredUrl);

      let url = new URL(filteredUrl);

      url.protocol = "webcal://";
      url.pathname += "/calendar.ics";

      url = applyCheckboxesToUrl(url, checkboxes);

      console.log("URL", url);

      setCalendarUrl(url.toString());
    }
  }, [region, checkboxes, baseUrl, apiUrl, filteredUrl]);

  // Region input
  const uniqueCountries = countries.filter((v, i, a) => a.map(i => i.country).indexOf(v.country) === i);

  const regionInput = (<Autocomplete
    options={uniqueCountries}
    autoHighlight
    getOptionLabel={(option: Country) => option.country}
    onChange={(_, value) => value ? setRegion(value as Country) : null}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Choose a country"
        inputProps={{
          ...params.inputProps,
          autoComplete: "none"
        }}
      />
    )}
  />)

  // Put together checkboxes
  function handleChecked(newVal: boolean, id: number) {
    checkboxes[checkboxes.findIndex(i => i.id === id)].checked = newVal;
    setCheckboxes([...checkboxes]);
  }

  const genreCheckboxes = genres
    .map(i => <GenreCheckbox key={i.id} name={i.name} id={i.id} onChange={handleChecked}></GenreCheckbox>)
    .map(i => <Grid item key={i.key} xs={6}>{i}</Grid>);

  const genreGrid = (
    <Grid container>
      {genreCheckboxes}
    </Grid>
  );

  const [movieData, setMovies] = useState<Array<{ original_title: string, release_date: string, id: string }>>([]);
  const [loadingMovies, setLoadingMovies] = useState<boolean>(false);

  useEffect(() => {
    async function fetchMovies() {

      const url = new URL(filteredUrl);

      url.protocol = "https://";

      const directUrl = url;

      try {
        const response = await fetch(directUrl);

        switch (response.status) {
          case 200:
            const data = await response.json();
            setMovies(data);
            break;
          case 404:
            setState({ ...state, open: true, errorMessage: "No movies found" });
            break;
          case 500:
            setState({ ...state, open: true, errorMessage: "Internal server error" });
            break;
          default:
            setState({ ...state, open: true, errorMessage: "Unknown error" });
            break;
        }
      }
      catch (e) {
        setMovies([]);
        setState({ ...state, open: true, errorMessage: `Error fetching movies: ${e.message}` });
      }
      finally {
        setLoadingMovies(false);
      }
    }

    if (filteredUrl && !state.errorMessage) {
      setLoadingMovies(true);
      fetchMovies();
    }

  }, [state, filteredUrl])

  const movieTable = loadingMovies ? (<TableRow><TableCell>Loading</TableCell><TableCell></TableCell></TableRow>) : movieData.map(i => {

    let d = DateTime.fromFormat(i.release_date, "yyyy-MM-dd").diffNow().shiftTo("weeks", "days")

    if (d.weeks === 0)
      d = d.shiftTo("days");

    const str = d.days < 0 ? "Last few days" : d.toHuman({ unitDisplay: "long", maximumFractionDigits: 0, minimumFractionDigits: 0 });

    return (
      <TableRow key={i.original_title}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row"><Link href={`https://www.themoviedb.org/movie/${i.id}`}>{i.original_title}</Link></TableCell>
        <TableCell>{str}</TableCell>
      </TableRow>
    )
  });

  const movies = (
    <TableContainer component={Paper}>
      <Table aria-label="upcoming films table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Released In</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{movieTable}</TableBody>
      </Table >
    </TableContainer >
  );

  //movieData.map(i => (<div>{i.original_title}</div>));

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <div>
      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h4' color="inherit" noWrap></Typography>
        </Toolbar>
      </AppBar>

      <main>
        <Container maxWidth="md">
          <Box sx={{ my: 4 }}>

            <Typography variant="h2" component="h1" gutterBottom align="center" color="textPrimary">
              Upcoming Movie Calendar
            </Typography>

            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              Live-calendar showing upcoming movies.
            </Typography>

            <Card sx={{ my: 4 }} elevation={2}>
              <CardContent>
                <Typography gutterBottom>Region</Typography>
                {regionInput}
              </CardContent>
            </Card>

            <Card sx={{ my: 4 }} elevation={2}>
              <CardContent>
                <Typography gutterBottom>Genres</Typography>
                {genreGrid}
              </CardContent>
            </Card>

            <Card sx={{ my: 4 }} elevation={2}>
              <CardContent>
                <Typography align='center'>
                  <Link href={calendarUrl}>{calendarUrl}</Link>
                </Typography>
              </CardContent>
            </Card>

            <Card sx={{ my: 4 }} elevation={2}>
              <CardContent>
                <Typography gutterBottom>Preview</Typography>
                {movies}
              </CardContent>
            </Card>
          </Box>
        </Container>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}>
          <Alert severity="error">{state.errorMessage}</Alert>
        </Snackbar>

      </main>
    </div>
  );
}

export default App;
